import React, { cloneElement, useEffect, useRef } from 'react';

import { motion } from 'framer-motion';
import { cn } from 'tailwind-config';
import { create } from 'zustand';

import { Loading } from '@components';

import { useMounted } from '@hooks';
import { useFunnelStore } from '@hooks/use-funnel/stores';

import { FunnelBottom, FunnelHeader } from './components';
import { useFunnelLayout } from './hooks';

interface FunnelProps {
  children: React.ReactNode;
  className?: string;
}

interface FunnelComponent {
  FunnelHeader: React.ReactNode;
  setFunnelHeader: (header: React.ReactNode) => void;
  FunnelBottom: React.ReactNode;
  setFunnelBottom: (bottom: React.ReactNode) => void;
}

/**
 * useFunnelStore에 통합 시 무한 렌더링이 일어나서, 별도의 스토어로 분리
 */
export const useFunnelComponent = create<FunnelComponent>()((set) => ({
  FunnelHeader: null,
  setFunnelHeader: (header: React.ReactNode) => set({ FunnelHeader: header }),
  FunnelBottom: null,
  setFunnelBottom: (bottom: React.ReactNode) => set({ FunnelBottom: bottom }),
}));

const Funnel = ({ children, className }: FunnelProps) => {
  const mounted = useMounted();
  const { screens, currentScreen } = useFunnelStore();
  const { FunnelHeader, FunnelBottom } = useFunnelComponent();
  const { controls, contentHeight, headerRef, bottomRef } = useFunnelLayout();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current || currentScreen === '') return;
    contentRef.current.scrollTo(0, 0);
  }, [currentScreen]);

  if (!mounted || screens.length === 0) return null;

  if (!currentScreen) return <Loading />;

  return (
    <>
      {FunnelHeader && cloneElement(FunnelHeader as any, { ref: headerRef })}
      {/* relative가 overflow auto 보다 parent에 있어야 자식의 absoulte 속성이 잘 들어감 */}
      <div className="relative">
        <div
          ref={contentRef}
          className={cn('scrollbar-all-hide overflow-y-auto overflow-x-hidden', className)}
          style={{ height: contentHeight }}
        >
          {/* 좌우 transition과 높이 변화 (contentHeight)를 하나의 컴포넌트로 할 시에 높이 변화가 천천이 일어나는 버그가 있어 다음과 같이 별도로 선언 */}
          <motion.div
            animate={controls}
            transition={{ type: 'spring', bounce: 0 }}
            className="h-full"
          >
            {children}
          </motion.div>
        </div>
      </div>
      {FunnelBottom && cloneElement(FunnelBottom as any, { ref: bottomRef })}
    </>
  );
};

Funnel.Header = FunnelHeader;
Funnel.Bottom = FunnelBottom;

export { Funnel };
