import { useRouter } from 'next/router';

import { PAGE_MAPPING } from '@constants/page-mapping';

/**
 * 해당 페이지에서 존재 가능한 queryFlag를 가져옵니다.
 * @param arg arg는 사용하지 않는 값이지만, 타입 추론을 위해 사용합니다.
 */
export const usePageQuery = <T extends keyof typeof PAGE_MAPPING>(arg?: T) => {
  const router = useRouter();

  const currentPageInformation = (PAGE_MAPPING as any)[router.pathname];

  const initialQuery =
    currentPageInformation && currentPageInformation['queryFlag']
      ? currentPageInformation['queryFlag']
      : {};

  return {
    ...initialQuery,
    ...router.query,
    isReady: router.isReady,
  } as (typeof PAGE_MAPPING)[T]['queryFlag'] & { isReady: boolean };
};

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

type ApproachCase<T extends keyof typeof PAGE_MAPPING> = (typeof PAGE_MAPPING)[T]['approachCase'];

/**
 * 페이지 이동 시 [id]와 같이 동적으로 변하는 부분을 처리하기 위한 함수입니다.
 * 현재 페이지가 가지고 있는 [id] 값으로 대체해줄 수도 있지만, 명시적으로 넣어주는게 더 좋은 방법인 것 같아
 * parameter에 url도 함꼐 넣어주는 것으로 결정했습니다.
 */
interface CommonPayload {
  url?: string;
}

/**
 * 다른 페이지로 이동할 때 (Route할 때) 사용합니다.
 * 해당 페이지에 접근 가능한 다양한 경로가 존재할 때, 명시적으로 이동하고 싶을 때 사용합니다.
 * @param page : 페이지 경로
 */
export const usePageRoute = <T extends keyof typeof PAGE_MAPPING>(page: T) => {
  const router = useRouter();

  return async <S extends keyof ApproachCase<T>>(
    action: S,
    payload?: ApproachCase<T>[S] extends Function
      ? ArgumentTypes<ApproachCase<T>[S]>[0] & CommonPayload
      : CommonPayload,
    // payload?: Partial<(typeof PAGE_MAPPING)[T]['queryFlag']> & ,
  ) => {
    let query = {};

    const data = (PAGE_MAPPING[page].approachCase as any)[action];

    if (typeof data === 'function') {
      query = data(payload);
    } else {
      query = data;
    }

    await router.push({
      pathname: payload?.url ?? page,
      query,
    });
  };
};
