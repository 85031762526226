import { StateCreator } from 'zustand';

import { FunnelStore } from './funnel-store';

export interface FunnelAnimationSlice {
  animationValue: 'goPrev' | 'goNext';
  setAnimationValue: (animationValue: 'goPrev' | 'goNext') => void;
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
  bottomHeight: number;
  setBottomHeight: (height: number) => void;
}

export const funnelAnimationSlice: StateCreator<FunnelStore, [], [], FunnelAnimationSlice> = (
  set,
) => ({
  animationValue: 'goNext',
  setAnimationValue: (animationValue) => set({ animationValue }),
  headerHeight: 0,
  setHeaderHeight: (height) => set({ headerHeight: height }),
  bottomHeight: 0,
  setBottomHeight: (height) => set({ bottomHeight: height }),
});
