import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { queryStringStorage } from '@config';

import { FunnelAnimationSlice, funnelAnimationSlice } from './funnel-animation';
import { FunnelScreenStore, funnelScreenSlice } from './funnel-screen';
import { PopUntilSlice, popUntilSlice } from './pop-unitl';
import { RouterSlice, routerSlice } from './router';

export type FunnelStore = FunnelScreenStore & PopUntilSlice & FunnelAnimationSlice & RouterSlice;

export const FUNNEL_PERSIST_KEYS = ['screens'];

export const useFunnelStore = create<FunnelStore>()(
  persist(
    (...a) => ({
      ...funnelScreenSlice(...a),
      ...popUntilSlice(...a),
      ...funnelAnimationSlice(...a),
      ...routerSlice(...a),
    }),
    queryStringStorage<FunnelStore>(FUNNEL_PERSIST_KEYS),
  ),
);
