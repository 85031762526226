import { useEffect } from 'react';

import { useRouter } from 'next/router';
import { deleteCurrentURLState } from 'shared-values';
import SuperJSON from 'superjson';

import { FUNNEL_PERSIST_KEYS, useFunnelStore } from './stores/funnel-store';
import { globalDataStore } from './stores/router';

interface FunnelProps<T extends readonly string[]> {
  screens: T;
  backRedirectUrl?: string;
  initState?: any;
  key?: {
    storageKey: string;
    initialData: any;
  };
  isHeaderVisible?: boolean;
  isProgressBar?: boolean;
  overrideGoPrev?: (goPrev: () => void) => void;
}

const useHandleScreen = <T extends readonly string[]>(initialScreens: T) => {
  const { setCurrentScreen, screens, setScreens, replaceScreen } = useFunnelStore();

  const { zustandStore, persistKey } = globalDataStore;

  const router = useRouter();

  const isReady = router.isReady;
  const queryScreen = router.query.currentScreen;

  const currentScreen = queryScreen ? SuperJSON.parse(queryScreen as string) ?? '' : '';

  // screen initialize
  useEffect(() => {
    setScreens(initialScreens as unknown as string[]);
  }, [initialScreens]);

  /**
   * router.query가 준비가 됐음에도 query Screen이 없으면 첫번째 화면을 넣어줌
   * 처음에 screen 정보가 없을 때만 실행
   */
  useEffect(() => {
    if (screens.length === 0 || currentScreen || !isReady) return;
    replaceScreen(screens[0]);
  }, [screens, isReady]);

  /**
   * queryScreen이 변경될 때마다 현재 화면을 업데이트
   */
  useEffect(() => {
    if (!currentScreen) return;
    setCurrentScreen(currentScreen as string);
  }, [currentScreen]);

  /**
   * query 상태 -> zustand 상태 업데이트하는 함수
   * 브라우저에서 뒤로가기를 헀을 때 zustandStore를 업데이트 해 줄 필요가 있음.
   * 각 data store에서 해줘도 되지만, Funnel와 연결시켜서 한번에 처리하도록 함.
   * 타입 문제가 있어서 다음과 같이 정의
   */
  useEffect(() => {
    if (!zustandStore || !('persist' in zustandStore)) return;
    (
      zustandStore.persist as {
        rehydrate: () => void | Promise<unknown>;
      }
    ).rehydrate();
  }, [router.query, zustandStore]);

  useEffect(() => {
    return () => {
      const funnelInitialState = useFunnelStore.getInitialState();
      useFunnelStore.setState(funnelInitialState);

      // reset data store
      if (zustandStore) {
        const initialState = zustandStore.getInitialState();
        zustandStore.setState(initialState);
      }

      // clean up url
      setTimeout(() => {
        deleteCurrentURLState([...persistKey, ...FUNNEL_PERSIST_KEYS]);
      }, 0);
    };
  }, []);
};

const useSetRouter = () => {
  const setRouter = useFunnelStore((state) => state.setRouter);
  const router = useRouter();

  useEffect(() => {
    if (router) {
      setRouter(router);
    }
  }, [router, setRouter]);
};

export const useFunnel = <T extends readonly string[]>({
  screens,
  backRedirectUrl,
}: FunnelProps<T>) => {
  const funnelStore = useFunnelStore();
  useSetRouter();
  useHandleScreen(screens);

  return { ...funnelStore, currentScreen: funnelStore.currentScreen as T[number] };
};
