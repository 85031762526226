import { useCallback, useEffect, useMemo } from 'react';

import { useAnimationControls } from 'framer-motion';
import { useWindowSize } from 'usehooks-ts';

import { useFunnelStore } from '@hooks/use-funnel/stores/funnel-store';

const NextScreenAnimation = [20, 0];
const PrevScreenAnimation = [-20, 0];

const useFunnelAnimation = () => {
  const controls = useAnimationControls();

  const { animationValue, currentScreen } = useFunnelStore();

  useEffect(() => {
    if (animationValue === 'goNext') {
      controls.start({ x: NextScreenAnimation });
    } else {
      controls.start({ x: PrevScreenAnimation });
    }
  }, [
    // 무조건 animationValue가 먼저 set되고, 그 다음에 currentScreen이 set되기 때문에
    // currentScreen만 의존성으로 넣어줌
    // animationValue만 의존성으로 넣어주면, animationValue는 2가지의 값밖에 없기 때문에 같은 값이 연속으로 set되면
    // useEffect가 실행되지 않음
    currentScreen,
  ]);

  return { controls };
};

export const useFunnelHeight = () => {
  const { height } = useWindowSize();
  const { headerHeight, bottomHeight, setHeaderHeight, setBottomHeight } = useFunnelStore(
    (state) => ({
      headerHeight: state.headerHeight,
      bottomHeight: state.bottomHeight,
      setHeaderHeight: state.setHeaderHeight,
      setBottomHeight: state.setBottomHeight,
    }),
  );

  const headerRef = useCallback((node: HTMLElement) => {
    if (!node) {
      setHeaderHeight(0);
      return;
    }
    if (node.className.includes('bg-transparent')) {
      setHeaderHeight(0);
      return;
    }
    setHeaderHeight(node.getBoundingClientRect().height);
  }, []);

  const bottomRef = useCallback((node: HTMLElement) => {
    if (!node) {
      setBottomHeight(0);
      return;
    }
    setBottomHeight(node.getBoundingClientRect().height);
  }, []);

  const contentHeight = useMemo(
    () => height - headerHeight - bottomHeight,
    [height, headerHeight, bottomHeight],
  );

  return { headerRef, bottomRef, contentHeight };
};

export const useFunnelLayout = () => {
  const animationResult = useFunnelAnimation();
  const heightResult = useFunnelHeight();

  return { ...animationResult, ...heightResult };
};
