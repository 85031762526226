import { forwardRef, useEffect } from 'react';

import { Spacer } from 'design-system';
import { cn } from 'tailwind-config';

import { useFunnelStore } from '@hooks/use-funnel/stores';

import { useFunnelComponent } from '../Funnel';

const FunnelBottomHeight = 72;

interface FunnelBottomProps {
  children: React.ReactNode;
  isShadow?: boolean;
  className?: string;
}
const FunnelBottomComponent = forwardRef<HTMLDivElement, FunnelBottomProps>(
  ({ children, className, isShadow = true }, ref) => {
    const bottomHeight = useFunnelStore((state) => state.bottomHeight);

    return (
      <>
        <Spacer style={{ height: bottomHeight }} />
        <div
          ref={ref}
          className={cn(
            'fixed bottom-0 z-50 w-full bg-white px-16 py-10',
            className,
            isShadow && 'shadow-[0px_-4px_16px_0px_rgba(0,0,0,0.06)]',
          )}
        >
          {children}
        </div>
      </>
    );
  },
);

export const FunnelBottom = forwardRef<HTMLDivElement, FunnelBottomProps>((props, ref) => {
  const { setFunnelBottom } = useFunnelComponent();

  useEffect(() => {
    const funnelBottom = <FunnelBottomComponent ref={ref} {...props} />;
    setFunnelBottom(funnelBottom);

    return () => {
      setFunnelBottom(null);
    };
  }, [props]);

  return <></>;
});

FunnelBottom.displayName = 'FunnelBottom';
