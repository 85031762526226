import { StateCreator } from 'zustand';

import { FunnelStore } from './funnel-store';

export interface PopUntilSlice {
  screenHistory: string[];
  setScreenHistory: (screenHistory: string[]) => void;
  popUntil: (screen: string) => void;
}

export const popUntilSlice: StateCreator<FunnelStore, [], [], PopUntilSlice> = (set, get) => ({
  screenHistory: [],
  setScreenHistory: (screenHistory) => set({ screenHistory }),
  popUntil: (targetScreen) => {
    const { screenHistory } = get();
    const index = screenHistory.indexOf(targetScreen);
    if (index === -1) {
      throw new Error('Invalid screen');
    }

    const popNum = screenHistory.length - index - 1;
    for (let i = 0; i < popNum; i++) {
      get().goPrev();
    }
  },
});
