import {
  ComponentProps,
  ReactNode,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ReactChannelIO, useChannelIOApi, useChannelIOEvent } from 'react-channel-plugin';

import { LogEvent } from '@analytics';
import { useGetAcademy, useGetUser } from '@db/collections';
import { NewIcon } from 'design-system';
import { useRouter } from 'next/router';
import { useDebounceCallback, useOnClickOutside } from 'usehooks-ts';

const APP_WIDTH = 500;
const PADDING = 16;
const ICON_SIZE = 54;
const OFFSET_NOT_FIGURED_OUT_YET = 3; // 코치 마크 없는 상태에서  왠지 모르지만 생기는 픽셀 차이가 있음...

function useChannelTalkTriggerPosition() {
  const [position, setPosition] = useState<{
    top: number;
    left: number;
  }>();

  const updatePosition = useDebounceCallback((_?: Event) => {
    const BottomButtons =
      document.querySelector('#SimulationReservationButtons') ||
      document.querySelector('#AcademyReservationButtons');
    const CoachMark = document.querySelector('#CoachMark');

    if (!BottomButtons) return;

    const BottomButtonsRect = BottomButtons.getBoundingClientRect();
    const CoachMarkRect = CoachMark?.getBoundingClientRect();
    const BottomButtonsY = BottomButtonsRect.y; // viewport 맨 위에서  BottomButtons 맨 위까지의 픽셀수
    const CoachMarkY = CoachMarkRect?.y; // viewport 맨 위에서  CoachMark 맨 위까지의 픽셀수

    const top = CoachMarkY
      ? CoachMarkY - ICON_SIZE - PADDING
      : BottomButtonsY - ICON_SIZE - PADDING - OFFSET_NOT_FIGURED_OUT_YET;

    const left = BottomButtonsRect.width - ICON_SIZE - PADDING;

    setPosition({
      top,
      left,
    });
  }, 10);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.addedNodes.length) {
          // 코치마크 로딩 되었을때
          for (const node of mutation.addedNodes) {
            if (node instanceof HTMLElement && node.querySelectorAll('#CoachMark')) {
              updatePosition();
            }
          }
        }
        if (mutation.removedNodes.length) {
          // 유저가 코치마크를 닫았을 때
          for (const node of mutation.removedNodes) {
            if (node instanceof HTMLElement && node.querySelectorAll('#CoachMark')) {
              updatePosition();
            }
          }
        }
      }
    });

    window.addEventListener('resize', updatePosition);
    document.addEventListener('DOMContentLoaded', updatePosition);

    const appContainer = document.querySelector('main');
    if (appContainer) {
      observer.observe(appContainer, { childList: true, subtree: true });
    }

    return () => {
      window.removeEventListener('resize', updatePosition);
      document.removeEventListener('DOMContentLoaded', updatePosition);
      observer.disconnect();
    };
  }, [updatePosition]);

  return position;
}

function ChannelTalkTrigger({
  children,
  pageName,
  tags = [],
}: {
  children?: ReactNode;
  pageName?: string;
  tags?: string[];
}) {
  const router = useRouter();
  const pagePath = router.asPath.split('?')[0];
  const ref = useRef<HTMLButtonElement>(null);
  const { user } = useGetUser();
  const academy = useGetAcademy(router.query.id as string);
  const [unreadCount, setUnreadCount] = useState(0);
  const position = useChannelTalkTriggerPosition();

  const { showMessenger, hideMessenger, setPage, updateUser, addTags } = useChannelIOApi();

  useChannelIOEvent('onBadgeChanged', (unread) => {
    setUnreadCount(unread);
  });

  useOnClickOutside(ref, () => {
    hideMessenger();
  });

  const handleChannelButtonClick = () => {
    setPage(pagePath);

    LogEvent.활성화.inquiryClick(academy.data);

    const name =
      pageName +
      `${user ? `[${user.realName ?? user?.name ?? ''}][${user.uid}]` : '[유저정보없음]'}${
        academy.data?.displayName ? `[${academy.data?.locationAttachedName}]` : '[학원정보없음]'
      }`;

    addTags([...(pageName ? [pageName] : []), ...tags]);

    updateUser({
      language: 'ko',
      profile: {
        email: user?.email,
        mobileNumber: user?.phoneNum,
        name,
        uid: user?.uid,
        academyId: academy.data?.id,
        academyName: academy.data?.locationAttachedName,
      },
    });
    showMessenger();
  };

  if (isValidElement(children))
    return cloneElement(children, { onClick: handleChannelButtonClick, ref } as any);

  if (position === undefined) return null;

  return (
    <button
      type="button"
      ref={ref}
      onClick={handleChannelButtonClick}
      style={{ top: position.top, left: position.left }}
      className="w-54 h-54 text-new-white text-new-Caption2-bold bg-new-black-75 fixed z-20 flex flex-col items-center justify-center rounded-[18px] shadow-[0_0_8px_rgba(0,0,0,0.1)]"
    >
      {unreadCount > 0 ? (
        <span className="text-new-Caption2-bold text-new-white bg-new-DTRed-400 absolute -right-2 -top-2 h-16 w-16 rounded-full">
          {unreadCount}
        </span>
      ) : null}
      <NewIcon icon="headphone-outlined" width={24} height={24} className="fill-new-white" />
      학원문의
    </button>
  );
}

export function ChannelTalkButton({
  children,
  ...props
}: ComponentProps<typeof ChannelTalkTrigger>) {
  return (
    <ReactChannelIO
      pluginKey={process.env.NEXT_PUBLIC_CHANNEL_IO_KEY ?? ''}
      language="ko"
      hideChannelButtonOnBoot
      autoBoot
    >
      <ChannelTalkTrigger {...props}>{children}</ChannelTalkTrigger>
    </ReactChannelIO>
  );
}
