import { StateCreator } from 'zustand';

import { FunnelStore } from './funnel-store';

const getNextScreen = (screens: string[], screen: string): string => {
  const currentIndex = screens.findIndex((v) => v === screen);
  return currentIndex === screens.length - 1 ? screens[currentIndex] : screens[currentIndex + 1];
};

export interface FunnelScreenStore {
  currentScreen: string;
  screens: string[];
  setScreens: (screens: string[]) => void;
  setCurrentScreen: (currentScreen: string) => void;
  goNext: (options?: { queryData?: Record<string, unknown>; screens?: string[] }) => void;
  goPrev: () => void;
}

export const funnelScreenSlice: StateCreator<FunnelStore, [], [], FunnelScreenStore> = (
  set,
  get,
) => ({
  screens: [],
  currentScreen: '',
  setScreens: (screens) => set({ screens }),
  setCurrentScreen: (currentScreen) => set({ currentScreen }),
  goNext: () => {
    const { screens, currentScreen, router, setAnimationValue, pushScreen } = get();
    const nextScreen = getNextScreen(screens, currentScreen);

    if (!nextScreen || !router) return;

    setAnimationValue('goNext');
    pushScreen(nextScreen);
  },
  goPrev: () => {
    const { setAnimationValue, router, popScreen } = get();

    if (!router) return;

    setAnimationValue('goPrev');
    popScreen();
  },
  reset: () => {
    set({ screenHistory: [], currentScreen: '', screens: [] });
  },
});
