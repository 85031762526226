import React, { forwardRef, useEffect } from 'react';

import { AppBar, AppBarItem, NewIcon } from 'design-system';
import { cn } from 'tailwind-config';

import { TOP_NAV_BAR_HEIGHT } from '@constants';
import { useFunnelStore } from '@hooks/use-funnel/stores/funnel-store';

import { useFunnelComponent } from '../Funnel';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type SubPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/**
 * FunnelHeader는 기본적으로 AppBar를 extends 하지만, AppBar의 layout을 사용하고 싶지 않을 경우
 * children으로 원하는 레이아웃을 넣을 수 있도록 함
 */
const FunnelHeaderComponent = forwardRef<
  HTMLDivElement,
  SubPartial<React.ComponentProps<typeof AppBar>, 'type' | 'leftItem' | 'rightItem'> &
    FunnelHeaderProps
>(
  (
    { className, isTransparent, leftItem, rightItem, showProgressBar, isVisible = true, ...props },
    ref,
  ) => {
    const { goPrev, currentScreen, screens } = useFunnelStore();

    if (!isVisible) return null;

    if (props.children) return <div ref={ref}>{props.children}</div>;

    return (
      <>
        <AppBar
          {...props}
          ref={ref}
          type="title-center"
          className={cn('fixed top-0 z-10 w-full', className, isTransparent && 'bg-transparent')}
          leftItem={
            // eslint-disable-next-line react/jsx-no-undef
            leftItem !== undefined ? (
              leftItem
            ) : (
              <AppBarItem
                item={<NewIcon icon="arrowline-outlined" size={24} className="fill-new-gray-900" />}
                onClick={goPrev}
              />
            )
          }
          rightItem={rightItem ?? [<></>]}
          progressBarOption={{
            showProgressBar: showProgressBar ?? true,
            progressPercentage: (screens.indexOf(currentScreen) + 1) / screens.length,
          }}
        />
        {!isTransparent && <div style={{ height: TOP_NAV_BAR_HEIGHT }} />}
      </>
    );
  },
);

interface FunnelHeaderProps {
  isTransparent?: boolean;
  showProgressBar?: boolean;
  isVisible?: boolean;
}

export const FunnelHeader = forwardRef<
  HTMLDivElement,
  SubPartial<React.ComponentProps<typeof AppBar>, 'type' | 'leftItem' | 'rightItem'> &
    FunnelHeaderProps
>((props, ref) => {
  const { setFunnelHeader } = useFunnelComponent();

  useEffect(() => {
    const funnelHeader = <FunnelHeaderComponent {...props} ref={ref} />;

    setFunnelHeader(funnelHeader);

    return () => {
      setFunnelHeader(null);
    };
  }, [props]);

  return <></>;
});

FunnelHeader.displayName = 'FunnelHeader';
