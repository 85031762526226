import { NextRouter } from 'next/router';
import SuperJSON from 'superjson';
import { StateCreator, StoreApi, UseBoundStore } from 'zustand';

import { FunnelStore } from './funnel-store';

export interface RouterSlice {
  router: NextRouter | null;
  setRouter: (router: NextRouter) => void;
  pushScreen: (nextScreen: string) => void;
  replaceScreen: (nextScreen: string) => void;
  connectDataStore: (dataStore: {
    zustandStore: UseBoundStore<StoreApi<any>> | null;
    persistKey: string[];
  }) => void;
  popScreen: () => void;
}

/**
 * globalDataStore는 리렌더링이 필요하지 않고, 참조용으로만 사용되기 때문에
 * zustand 바깥에서 선언해서 사용함.
 */
export let globalDataStore: {
  zustandStore: UseBoundStore<StoreApi<any>> | null;
  persistKey: string[];
} = {
  zustandStore: null,
  persistKey: [],
};

export const routerSlice: StateCreator<FunnelStore, [], [], RouterSlice> = (set, get) => ({
  router: null,
  setRouter: (router) => set({ router }),
  connectDataStore: (dataStore) => {
    globalDataStore = dataStore;
  },
  pushScreen: (nextScreen: string) => {
    const { router } = get();
    if (!router) return;

    const searchParams = new URLSearchParams(window.location.search.slice(1));

    /**
     * nextjs의 router는 window.replaceState의 데이터를 동기화하지 못하기 때문에
     * 직접 URL을 읽어서 queryParmas를 추출해냄
     */
    const queryParams = Array.from(searchParams.entries()).reduce<Record<string, string>>(
      (acc, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      {},
    );

    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        ...queryParams,
        currentScreen: SuperJSON.stringify(nextScreen),
      },
    });
  },
  replaceScreen: (nextScreen: string) => {
    const { router } = get();
    if (!router) return;
    router.replace({
      pathname: router.pathname,
      query: {
        ...router.query,
        // url 로드 시에 JSON.parse를 이용할 것이기 때문에 string으로 변환
        currentScreen: SuperJSON.stringify(nextScreen),
      },
    });
  },
  fallbackURL: '',
  popScreen: () => {
    const { router } = get();
    if (!router) return;

    router.back();
  },
});
